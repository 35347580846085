import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import HeroOther from "../components/HeroOther"
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import Title from "../components/Title"
import SubTitle from "../components/SubTitle"
import Descriptions from "../components/Descriptions"
import Brands from "../components/base/Brands";
import WhyChooseUs from "../components/base/WhyChooseUs";
import Testimonial from "../components/base/Testimonial"
import CustomFooter from "../components/CustomFooter";


export const NetworkPenetrationTestingPageTemplate = ({
  seo,
  hero,
  contactus,
  aboutsection,
  scheduleSection,
  leveragesection,
  wucsection,
  wpcsection,
  fixedimagesection
}) => (
    <div className="custompages">
      <SEO title={seo.title} description={seo.description} titlePostfix="%s" article_section={seo.article_section} blogservice={true} />
      <HeroOther data={hero} />
      <section className="section">

        <div className="container">
          <div className="columns">
            <div className="column">
              <SubTitle textAlign="center" title={contactus.title} />
            </div>
          </div>
          <div className="columns">
            <div className="column  has-text-centered">
              <a href={contactus.buttonlink} >
                <button class="btn-light">{contactus.buttontxt}</button>
              </a>
            </div>
          </div>
        </div>


      </section>


      <section className="section" style={{ background: `${scheduleSection.sectionbg}` }}>
        <div className="container scedulesection">


          <div className="columns" >
            <div className="column is-8 " style={{ margin: "0rem 0" }}>

              {
                scheduleSection.description.list.map((description, i) => {
                  return (
                    <div key={i}>
                      <p style={{ margin: "1rem 0", color: "#53c5d1", }}>{description.title}</p>
                    </div>
                  )
                })
              }

              <a href={scheduleSection.buttonlink} >
                <button style={{ marginTop: 10 }} class="btn-light">{scheduleSection.buttontxt}</button>
              </a>
            </div>

            <div className="column mobile-center has-text-centered hero-image">
              <div style={{ maxWidth: 250, height: 90, marginTop: 104 }}>
                <PreviewCompatibleImage imageInfo={{ image: '/img/Cypress-Data-Defense-Website-Yellow-Alien-5.png' }} />

              </div>
            </div>
          </div>

        </div>
      </section>

      <section className="section" style={{ background: `${leveragesection.sectionbg}` }}>
        <div className="container assesmentsection scedulesection">
          <div style={{
            marginTop: "1rem"
          }}>
            <div className="columns" >
              <div className="column is-7">
                < SubTitle title={leveragesection.title} color={leveragesection.textcolor} />
              </div>
            </div>
            {
              leveragesection.description.list.map((description, i) => {
                return (
                  <div key={i}>
                    <p style={{ margin: "1rem 0", color: "#ffffff" }}>{description.title}</p>
                  </div>
                )
              })
            }
            <ul style={{ listStyleType: 'disc', paddingLeft: '3rem', color: '#fff' }}>
              {
                leveragesection.orderlist.list.map((order, i) => {
                  return (
                    <li style={{ marginTop: '1rem' }}>
                      {order.title}
                    </li>
                  )
                })
              }
            </ul>
            {
              leveragesection.description2.list.map((description, i) => {
                return (
                  <div key={i}>
                    <p style={{ margin: "1rem 0", color: "#ffffff" }}>{description.title}</p>
                  </div>
                )
              })
            }

            <div className="columns" style={{ margin: "1.1rem 0 0" }}>
              <div className="column  is-8">
                <a href={leveragesection.buttonlink} >
                  <button class="">{leveragesection.buttontxt}</button>
                </a>
              </div>
              <div className="column mobile-center has-text-centered hero-image">
                <div style={{ maxWidth: 190, height: 55, marginTop: 0 }}>
                  <PreviewCompatibleImage imageInfo={{ image: '/img/Cypress-Data-Defense-Website-Pspikes-Alien-2.png' }} />

                </div>
              </div>

            </div>





          </div>
        </div>
      </section>

      <section className="section powerfulsection scedulesection" style={{ background: `${aboutsection.sectionbg}` }}>
        <div className="container ">
          <div style={{ marginTop: "1rem" }}>
            <SubTitle title={aboutsection.title} color={aboutsection.textcolor} />
            <Title title={aboutsection.secondtitle} color={aboutsection.textcolor} />
            <SubTitle title={aboutsection.subheader1} color={aboutsection.textcolor} margin="1.5rem 0" />
            <Descriptions descriptions={aboutsection.descriptions} color="#53c5d1" margin="1rem 0" />
            <SubTitle title={aboutsection.subheader2} color={aboutsection.textcolor} margin="1.5rem 0" />
            <Descriptions descriptions={aboutsection.abovedescriptions} color="#53c5d1" margin="1rem 0" />
          </div>



          <ul style={{ listStyleType: 'disc', paddingLeft: '3rem', color: '#53c5d1' }}>

            <li style={{ marginTop: '1rem' }}>
              <font style={{ fontWeight: 'bold', color: '#ffffff' }}> Automated tools </font>can include various testing platforms alongside proprietary and open-source tools and frameworks.<br /><br />
              These tools are used to identify system vulnerabilities and to execute exploits to take advantage of system weaknesses. This offers a quick and easy way to test a system, but automated tools alone are not enough for penetration testing. It is also important to conduct manual testing by humans.

                    </li>
            <li style={{ marginTop: '1rem' }}>
              <font style={{ fontWeight: 'bold', color: '#ffffff' }}> Manual methods </font>include code injection, adapted shell scripting, malware usage, and other advanced techniques. <br /><br />
              This offers a more in-depth penetration testing that can result in the use of uncommon attack vectors, giving your company a better, more realistic view of a real-life attack.<br /><br />
              Manual testing uses proprietary methodologies, custom exploits/payloads, and scripts to compromise systems, account passwords, sensitive data, etc. This penetration testing methodology also allows security engineers to verify the findings of automated scanning tools in order to eliminate false positives.

                      </li>

          </ul>






          <Descriptions descriptions={aboutsection.bottomdescriptions} color="#53c5d1" margin="1rem 0" />


          <div className="columns" >
            <div className="column is-6 " >
              <br />
              <a href={aboutsection.buttonlink} >
                <button class="btn-light">{aboutsection.buttontxt}</button>
              </a>
            </div>
            <div className="column is-2 mobile-center has-text-centered">
            </div>
            <div className="column mobile-center has-text-centered hero-image">
              <div style={{ maxWidth: 250, height: 90 }}>
                <PreviewCompatibleImage imageInfo={{ image: '/img/mobile_testing_services.png' }} />

              </div>
            </div>
          </div>



        </div>


      </section>


      <Brands />
      <WhyChooseUs
        title="We offer specialized network penetration testing services."
        list={[{
          title: 'We are security experts who train others.',
          para: 'Our application security specialists regularly instruct for large corporations and global training institutions. We teach developers and organizations how to properly perform application and network penetration testing to ensure that their networks are secure.'
        }, {
          title: 'We are network security engineers.',
          para: 'We aren’t only experts in security, we also understand how malicious attackers can gain access to your network. We can leverage this knowledge to conduct penetration testing and provide the most thorough security reviews for your networks as well as applications.',
        }]} />
      <Testimonial />
      <CustomFooter data={{
        title: 'Want to Test the Security of Your Corporate Network?',
        subtitle: '',
        para: [
          'Let our security engineers conduct penetration testing to identify vulnerabilities in your network systems and determine the efficiency of your security controls.'

        ],
        linkTitle: 'Talk to Our Network Security Experts'
      }} />





    </div>
  )

NetworkPenetrationTestingPageTemplate.propTypes = {
  seo: PropTypes.object,
  hero: PropTypes.object,
  contactus: PropTypes.object,
  scheduleSection: PropTypes.object,
  leveragesection: PropTypes.object,
  aboutsection: PropTypes.object,
  wucsection: PropTypes.object,
  wpcsection: PropTypes.object,
  fixedimagesection: PropTypes.object
}

const NetworkPenetrationTestingPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <NetworkPenetrationTestingPageTemplate
        seo={frontmatter.seo}
        hero={frontmatter.hero}
        contactus={frontmatter.contactus}
        scheduleSection={frontmatter.scheduleSection}
        leveragesection={frontmatter.leveragesection}
        aboutsection={frontmatter.aboutsection}
        wucsection={frontmatter.wucsection}
        wpcsection={frontmatter.wpcsection}
        fixedimagesection={frontmatter.fixedimagesection}
      />
    </Layout>
  )
}

NetworkPenetrationTestingPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default NetworkPenetrationTestingPage

export const pageQuery = graphql`
  query NetworkPenetrationTestingPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "network-penetration-testing" } }) {
      frontmatter {
        seo {
          title
          description
          article_section
        }
        hero{
          title
          secondtitle
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          midimage{
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 260, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          sideimage{
            alt
            align
            image {
              childImageSharp {
                fluid(maxWidth: 260, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          description
          buttontxt
          buttonlink
        }
        contactus {
          title
          secondtitle
          buttontxt
          buttonlink
        }
        scheduleSection{
          sectionbg
          textcolor
          description{
            list{
              title
            }
          }
          buttontxt
          buttonlink
        }
        leveragesection{
          sectionbg
          textcolor
          title
          description{
            list{
              title
            }
          }
          orderlist{
            list{
              title
            }
          }
          description2{
           list{
              title
            }
          }
          buttontxt
          buttonlink

        }
        aboutsection {
          sectionbg
          textcolor
          title
          secondtitle
          subheader1
          descriptions
          subheader2
          abovedescriptions
          sidedescriptions
          bottomdescriptions
          image {
            childImageSharp {
              fluid(maxWidth: 350, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          buttontxt
          buttonlink
        }
        wucsection {
          sectionbg
          textcolor
          title
          secondtitle
          subheader
          descriptions
          sidedescriptions {
            title
            description
          }
          image {
            childImageSharp {
              fluid(maxWidth: 300, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        wpcsection {
          title
          secondtitle
          subheader
          sidedescriptions
          descriptions
          image {
            childImageSharp {
              fluid(maxWidth: 350, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        fixedimagesection {
          title
          secondtitle
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
